/* CSS for politihøgskolen */

// x-styles
@import "../../../../../../../lib/x1/css/x-0.less";
@import "../../../../../../../lib/x1/css/optional/x-consent-subtle.less";

// local files
// variables
@import 'variables.less';

// Libraries
@import "typography.less";
@import "utils.less";

// document types
@import 'person.less';
@import 'research.less';
@import 'studies.less';
@import 'components.less'; // global stuff like menu, accordion, nav-links, buttons, search-fields etc.
@import 'frontpage.less';

#crisis-banner, #crisis-banner-demo {
  background-color: var(--color-crisis);
  a {
      color: black;
  }
  .crisis-banner-inner {
    color: black;
    width: fit-content;
    max-width: 100vw;
    margin: 0 auto;
    padding-left: 50px;
    position: relative;
    &::before {
      background: var(--img-alert) no-repeat center;
      width: 1.7em;
      height: 2em;
      top: -4px;
      left: 0px;
      position: absolute;
      background-size: contain;
    }
  }
}

// x-candidate
.row-all-colored.row-all-no-margin-bottom {
    margin-bottom: 0;
}
#vrtx-collections {
   display: none;
}
body#vrtx-tagview .vrtx-resources .vrtx-resource {
    max-width: 290px;
}

.sidebar-menu-wrapper #supplementary-menu {
    display: block;
}

//local overrides
.vrtx-mode-preview #main {
  min-height: auto;
}

// Consent (is working but is something strange in markup/css that causes
// blue background to take whole page and is impossible to set consent background to white)

html body .consent.consent-a,
html body .consent-settings {
  h1 {
    background-color: transparent;

    &::before {
      display: none;
    }
  }
}

// Header

html[lang=en] #header .logo {
  background: none;
  background-image: var(--logo-en)
}
#head-wrapper{
  background-color: var(--color-dark-blue);
  #header {
    align-items: center;
      .logo {
          background: var(--logo-inv) no-repeat;
          width: 276px;
          height: 40px;
          @media (@screen-below-medium) {
              width: 200px;
              background-size: contain;
          }
      }
    #header-tools {
      #header-language{
        color: white;
        > span {
          border: none;
          border: 2px solid white;
        }
      }
      .toggle-search{
        color: white;
        &::before{
          background-image: var(--search-inv);
        }
      }
      .sidebar-menu-toggle{
        &::before{
          background-image: var(--menu-inv);
        }
      }
      #header-search-form {
        input[type="text"]::placeholder {
          color: white;
        }
      }
      .sidebar-menu-toggle, #header-search-form {
        color: white;
      }
      span{
        text-transform: uppercase;
      }
    }
  }
}
// language select
// on small screen, hide language select
// display it inside main menu when this is open
@media (@screen-below-medium) {
  #header-tools {
    grid-template-columns: auto auto;
    #header-language {
      // faux position inside menu
      position: absolute;
      right: 0;
      top: 82px;
      transition: all .5s;
    }
  }

  // hide lang select unless main menu is open
  body:not(.sidebar-menu-wrapper-visible) {
    #header-tools {
      #header-language {
        display: none;
      }
    }
  }

  // when search open, move lang select down
  body.header-search-visible {
    #header-tools {
      #header-language {
        margin-top: 100px;
      }
    }
  }
}
// header ^

// Breadcrumbs
#breadcrumbs {
  padding-top: var(--space-xs);
  margin-bottom: 0;
  padding-left: 0;
  #vrtx-breadcrumb-wrapper {
    max-width: var(--width-total-max);
    margin: 0 auto;
    span::after {
      content: "\002F"; // slash
      background: none;
      width: 0.7em;
      margin-left: 0.7em;
    }
  }
}

.the-frontpage #breadcrumbs {
  display: none;
}
// Breadcrumbs ^

@media (max-width: 751px) {
  body#vrtx-article-listing,
  body#vrtx-collection,
  body#vrtx-tagview,
  body#vrtx-research-group-listing {
    h1 {
      margin-bottom: var(--space-s);
      padding-bottom: var(--space-s);
    }
    .vrtx-introduction {
      max-width: var(--width-total);
    }
  }
}

#vrtx-fs-studieprogram-listing,
#vrtx-event-listing,
#vrtx-project-listing,
#vrtx-fs-emne-listing,
#vrtx-uh-person-listing {
  .vrtx-introduction {
    max-width: var(--width-total);
  }
}

#vrtx-structured-article , #vrtx-structured-event, #vrtx-structured-project{
    h1, .vrtx-introduction {
        max-width: var(--width-total);
    }
    .vrtx-introduction {
      +.vrtx-introduction-image,
      +.vrtx-byline {
        margin-top: -50px;
      }
    }
    .vrtx-byline {
      +.vrtx-introduction-image {
        margin-top: -50px;
      }
    }
    .vrtx-imagetext, figcaption {
      margin-bottom: var(--space-m);
    }
    #vrtx-content {
        max-width: var(--width-total);
        margin-left: auto;
        margin-right: auto;
    }
}

.vrtx-image-gallery-include-outer-wrapper {
  margin-left: -150px;
}


#vrtx-structured-article .vrtx-article-body .image-center img {
  @media (min-width: 1101px) {
    margin-left: -50px;
  }
  @media (min-width: 1141px) {
    margin-left:calc(520px - 50vw);
  }
}

.vrtx-introduction-image {
  // Override table display to allow calculation of top background left pos
  display: block;
  max-width: none;
  .vrtx-imagetext {
    // Override table-caption display and limit width
    display: block;
    max-width: var(--width-text-max);
  }
  img {
    padding-bottom: var(--space-s);
  }
  &:has(.vrtx-imagedescription) img {
    padding-bottom: 0;
  }
}

nav#left-menu-same-level-folders,
nav#global-sub-menu {
    display: none;
}

  .vrtx-article-body, #vrtx-frontpage {
  @media (min-width: 1101px) {
    .vrtx-facts-container.vrtx-container-left {
        float: left;
        width: 350px;
        margin-left: 0;
    }
    .vrtx-facts-container.vrtx-container-right {
      float: right;
      width: 400px;
      margin-right: -100px;
    }
  .vrtx-facts-container.vrtx-container-left.vrtx-facts-container, .vrtx-facts-container.vrtx-container-right.vrtx-facts-container {
      clear: both;
    }
  }
  .vrtx-facts-container {
      background-color: var(--color-box-bg);
      color: var(--color-black);
      font-size: var(--font-size-small);
      padding: 30px;
      margin: 40px 0;
  }
}

/*
Blue top background

NOTE: Calculation of left pos. for the ::before element
requires that the relative elements have automatic width.
*/

body {
  overflow-x: hidden;

  #breadcrumbs,
  h1,
  .vrtx-introduction,
  .vrtx-introduction-image,
  .vrtx-byline,
  .vrtx-context-box,
  .sub-title,
  #vrtx-fs-studieprogram-facts,
  #vrtx-fs-emne-facts,
  #vrtx-person-top-area,
  #study-how-to-apply-link,
  &#vrtx-fs-emne .connected-links {
    position: relative;
    padding-bottom: var(--space-s);
    &,
    &::before {
      background-color: var(--color-dark-blue);
    }
    &::before {
      content: "";
      position: absolute;
      z-index: -1;
      bottom: 0;
      left: calc(50% - 50vw); // Calculate left pos.
      width: 100vw;
      height: 2000px; // High value to ensure cover
    }
    &,
    * {
      color: white;
    }
  }
  // Exception for error-page (404)
  &#error {
    h1 {
      background: none;
      color: inherit;
      &::before {
        display: none;
      }
    }
  }
}

.vrtx-mode-edit {
  h1, .vrtx-introduction, .title-text, .fields {
    background-color: white;
    *{
      color: black;
    }
    color: black;
    &::before {
      display: none;
    }
    input{
      width: fit-content;
      margin-right: 4px;
      margin-left: 4px;
    }
  }
}

.vrtx-feed .item-title {
  &::after {
    content:"";
    background-color: var(--color-black);
    display: block;
    height: 4px;
    width: 40px;
    margin-top: var(--space-xs);
    margin-bottom: var(--space-xs);
  }
}

.vrtx-date-icon {
  background-color: var(--color-yellow);
}

body#vrtx-article-listing .vrtx-introduction{
  margin-top: -50px;
}

.sidebar-menu-wrapper .sidebar-global-menu {
  border-top: none;
  a{
    font-weight: 600;
  }
}

#footer-wrapper h2, #footer-wrapper .menu-label {
    font-weight: 600;
    font-variant: all-small-caps;
}

@media (max-width: 1300px) {
  .vrtx-image-gallery-include-outer-wrapper {
    margin-left: 0;
  }
}

.visit-table{
  font-size: 2.2rem;
  th{
    padding-left: 0px;
    padding-right: 20px;
    border-width: 1px 0px;
    font-weight: 400;
  }
  td{
    border-width: 1px 0px;
  }
}
// project-listing
#vrtx-project-listing, #vrtx-research-group-listing {
  #main #right-main {
     >ul {
      padding-left: 0;
      font-size: 2.8rem;
         >li {
             &::marker {
                 color: transparent;
             }
         }
     }
     ul ul {
      font-size: 1.8rem;
         list-style-type: disc;
         li::marker {
             color: var(--color-dark-blue);
         }
     }
  }
}

.vrtx-feed-link {
  display: none;
}
@media print {
  #header-tools, #footer-wrapper {
    display: none;
  }
}

#footer-wrapper{
  background-color: var(--color-dark-blue);
  color: white;

  .vrtx-dropdown-link::after{
    background-image: var(--arrow-footer-dropdown);
  }
}