.grid-container.crisis-banner, .grid-container.info-message {
    display: flex;
    align-items: center;
    justify-content: center;
}

.grid-container.info-message.alert {
    strong {
        padding: var(--space-m) 0;
        &:before {
            top: 32px;
        }
    }
    background-color: var(--color-crisis);
}

.grid-container.info-message.info {
    background-color: var(--color-info);
    strong {
        padding: var(--space-s) 0;
        &:before {
            background-image: var(--icon-info-message);
            background-size: contain;
            height: 30px;
            width: 30px;
            top: 18px;
            margin-left: -52px;
        }
    }
}

.sidebar-menu-wrapper {
    .sidebar-menu {
        background-color: var(--color-dark-blue);
        a {
            color: white;
        }
        .sidebar-global-menu {
            border: none;
        }
    }
}

#submenu {
  max-width: var(--width-total-max);
  padding: 0 50px;
  margin: 0 auto;
}
.main .vrtx-subfolder-menu a::before {
  background-image: var(--arrow);
  width: 16px;
}

.navigation-links {
    ul{
        row-gap:var(--space-s);
    }
    li a {
        background-color: var(--color-secondary-green-light);
        padding: var(--space-s) 60px var(--space-s) var(--space-s);
        border-radius: 0;
        font-size: 2.1rem;
        font-weight: 600;
        &:has(strong) {
            font-size: 1.8rem;
            font-weight: 400;
            strong {
                font-size: 2.6rem;
                font-weight: 600;
            }
        }
        &::after {
            background-image: var(--arrow-short);
            width: 32px;
            height: 32px;
            top: var(--space-s);
        }
    }
}

.read-more, .vrtx-more a, .vrtx-read-more, .all-messages{
  font-weight: 600;
  font-size: 1.8rem;
  &::before {
    content: "";
    display: none;
  }
  &::after{
    content:"";
    background: var(--arrow-read-more) no-repeat center top;
    position: absolute;
    background-size: contain;
    margin-left: 10px;
    margin-top: 2px;
    width: 1.35em;
    height: 1.35em;
  }
}

h2.accordion {
  font-size: 3rem;
}
h3.accordion, h4.accordion {
  font-weight: 400;
}
h2, h3, h4 {
    &.accordion {
        font-weight: 400;
        padding: var(--space-s) var(--space-m) var(--space-s) 0;
        margin:0; 
        width: auto;
        display:flex;
        align-items: center;
        &::before{
            display: none;
        }
        &::after{
            content: "";
            background-image: var(--arrow-small);
            background-repeat: no-repeat;
            background-size: contain;
            position: absolute;
            width: 17px;
            height: 17px;
            right: 0;
            display: inline-block;
            transform: rotate(90deg);
            transition: all .3s ease-in;
        }
    }
}

details {
  margin-bottom: var(--space-s);
  + details {
    margin-top: -20px;
  }
  &.accordion-wrapper {
    align-items: center;
    gap: var(--space-s);
    border-top: 1px solid var(--color-neutral-tint-opaque-80);
    border-bottom: 1px solid var(--color-neutral-tint-opaque-80);
    &:has(+ .accordion-wrapper) {
      border-bottom: none;
    }
    parent:last-child {
      border-bottom: 1px solid var(--color-neutral-tint-opaque-80);
    }
    &[open] {
      .accordion {
        &::after {
          transform: rotate(-90deg);
        }
      }
    }
    summary {
      width: 100%;
    }
  }
}

blockquote{
    margin: var(--space-m) 0;
    p:first-child{
        font-size: 3.7rem;
        font-weight: 300;
        line-height: 125%; 
    }
    &::before{
        content:"";
        background-image: var(--icon-quote);
        background-repeat: no-repeat;
        width:66px;
        height:43px;
        padding-bottom: var(--space-s);
    }
}

#study-program-finder .studies-filter .secondary-filter-group{
    display: block;
    .dropdown-filter{
        padding: var(--space-s) 0;
    }
}

.button, .button-loud {
    border-radius: 235px;
    color: var(--color-black);
    padding-left: var(--space-s);
    &::after{
        background-image: var(--arrow-medium);
    }
    @media (@screen-below-medium) {
        font-size: 1.8rem;
    }
}
.button{
  border: 2px solid var(--color-dark-blue);
}
.button-loud{
  background-color: var(--color-yellow); 
}

input {
  width: calc(100% - 63px);
  max-width: 650px;
}

input, button, select {
  padding: 18px 20px;
  border-radius: 0;
}

#search-container {
  button {
    margin-left: -5px;
  }
}
  
#header-tools .search-form,  #main-menu .search-form, .vrtx-search-container, body#error .error-404, #search-container  {
  form, form.search-form {
    column-gap: 0;
    button {
      background-image: url(../images/search.svg);
      background-repeat: no-repeat;
      background-position: center;
      background-color: var(--color-yellow);
      padding: 17px 30px;
      border-width: 2px 2px 2px 1px;
      border-radius: 0;
      text-indent: -100000px;
    }
  }
}

#header-tools .search-form, #main-menu .search-form {
  bottom: 0;
  form {
    input, button, select {
      border-color: var(--color-yellow);
    }
  }
}

.link-list li:has(a)::before {
  background-image: var(--arrow-medium);
  width: 16px;
}

// Custom subject list variant with depth=[2] and first level hidden
.main .subject-list .vrtx-subfolder-menu {
  ul ul {
    padding-left: 0;
  }
  > ul > li > a {
    display: none; // Hide first level
  }
}

body#vrtx-structured-project .vrtx-byline,
#vrtx-fs-studieprogram-facts, #vrtx-fs-emne-facts {
  background-color: var(--color-dark-blue);
  color: white;
  padding-bottom: 1px;
  h4, h2 {
      display: none;
  }
  dl, dl.time-and-place-columns {
    max-width: var(--width-total-max);
    margin: 0 auto;
    margin-bottom: var(--space-m);
    border-left: 4px solid var(--color-yellow);
    padding-left: 50px;
    display: flex;
    flex-wrap: wrap; 
    gap: var(--space-s) 50px;
    @media (@screen-below-medium) {
        flex-direction: column;
        padding-left: var(--space-s);
        row-gap: var(--space-xs);
    }
    .dg {
      max-width: 240px;
      @media (@screen-below-medium) {
        max-width: none;
      }
    }
    dt {
        font-size: 1.5rem;
        font-weight: 400;
    }
    dd {
        font-size: 1.8rem;
        font-weight: 700;
    }
    dt, dd {
      @media (@screen-below-medium) {
        display: inline-block;
        font-size: 1.8rem;
      }
    }
  }
  dl.time-and-place-columns {
    dt:after {
      content:":";
    }
  }
}